import React from 'react';
import {Routes,Route} from "react-router-dom";
import AddCustomer from './pages/Contacts/AddCustomer';
import Customers from './pages/Contacts/Customers';
import Dashboard from './pages/Dashboard/Dashboard';
import Products from './pages/Items/Products';
import Make from './pages/Make/Make';
import Sales from './pages/Sales/Sales';
import Category from './pages/Settings/Categories/Category';
import Operations from './pages/Settings/Operations/Operations';
import Settings from './pages/Settings/Settings';
import TaxRates from './pages/Settings/TaxRates/TaxRates';
import UnitsOfMeasure from './pages/Settings/UOM/UnitsOfMeasure';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Sales/>} />
      <Route path='/sales' element={<Sales/>} />
      <Route path='/manufacturingorders' element={<Make/>} />
      <Route path='/settings' element={<Settings/>} />
      <Route path='/settings/uoms' element={<UnitsOfMeasure/>} />
      <Route path='/settings/taxRates' element={<TaxRates/>} />
      <Route path='/settings/categories' element={<Category/>} />
      <Route path='/settings/operations' element={<Operations/>} />
      <Route path='/contacts/customers' element={<Customers/>} />
      <Route path='/add-customer' element={<AddCustomer/>} />
      <Route path='/products' element={<Products/>} />

    </Routes>
  );
}

export default App;
