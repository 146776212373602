import React,{useMemo, useState} from 'react'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


const CustomerView = () => {
    const [rowData] = useState([
        { name: 'test', email: 'abc@gmail.com', phone: 'Rob Decor', comment: 'test' },
    
        ]);
    
    const [columnDefs] = useState([
    { 
        field: '' , 
        checkboxSelection: true, 
        filter: false,
        maxWidth: 50,

    },
    { 
       field: 'name' , 
       headerName: "Name", 
       sortable: true, 
       cellRenderer: function(params) {
        return <a href="/add-customer" target="_blank" rel="noopener"> {params.value} </a>
    }
    },
    
    { 
        field: 'email', 
        headerName: "E-mail Address", 
        sortable: true, 
    },
    { 
        field: 'phone' , 
        headerName: "Phone Number",
        sortable: true, 
    },
    { 
        field: 'comment' , 
        headerName: "Comment", 
        sortable: true, 
    },
    
    ]);
    
    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          filter: true,
          floatingFilter: true,
          autoHeight: true, 
        };
      }, []);    

  return (
    <>
        <Container maxWidth={false}>
            <Grid container>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <div className='action-buttons'>
                        <IconButton disabled ><DeleteIcon/></IconButton>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{height: 400}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            rowDragManaged={true}
                            defaultColDef={defaultColDef}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default CustomerView