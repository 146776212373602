import React,{useMemo, useState} from 'react'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const ProductLists = () => {
    const [rowData] = useState([
        { name: 'Table', sku: 'Tab01023', category: 'Table', salePrice: '580.00', cost: '308.00', profit: '282.00', margin: '48.2%', prodtime: '9h' },
    
        ]);
    
    const [columnDefs] = useState([
    { 
        field: '' , 
        checkboxSelection: true, 
        filter: false,
        maxWidth: 50,

    },
    { 
       field: 'name' , 
       headerName: "Name", 
       sortable: true, 
       cellRenderer: function(params) {
        return <a href="#" target="_blank" rel="noopener"> {params.value} </a>
    }
    },
    
    { 
        field: 'sku', 
        headerName: "Variant Code/SKU", 
        sortable: true, 
    },
    { 
        field: 'category' , 
        headerName: "Category",
        sortable: true, 
    },
    { 
        field: 'salePrice' , 
        headerName: "Default Sale Price", 
        sortable: true, 
    },
    { 
        field: 'cost' , 
        headerName: "Cost", 
        sortable: true, 
        filter: false,
    },
    { 
        field: 'profit' , 
        headerName: "Profit", 
        sortable: true, 
        filter: false,
    },
    { 
        field: 'margin' , 
        headerName: "Margin", 
        sortable: true,
        filter: false, 
    },
    { 
        field: 'prodtime' , 
        headerName: "Prod. Time", 
        sortable: true, 
        filter: false,
    },
    
    ]);
    
    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          filter: true,
          floatingFilter: true,
          autoHeight: true, 
        };
      }, []);    

  return (
    <>
        <Container maxWidth={false}>
            <Grid container>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <div className='action-buttons'>
                        <IconButton color="primary"><FileDownloadOutlinedIcon/></IconButton>
                        <IconButton disabled ><PrintIcon/></IconButton>
                        <IconButton disabled ><EditIcon/></IconButton>
                        <IconButton disabled ><DeleteIcon/></IconButton>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{height: 400}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            rowDragManaged={true}
                            defaultColDef={defaultColDef}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default ProductLists