import React, {useState, useMemo} from 'react'
import PropTypes from 'prop-types';
import Header from '../../../components/Header/Header'
import Grid from '@mui/material/Grid';
import { Container, Card, Button, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AgGridReact } from 'ag-grid-react';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form } from 'formik';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const Operations = () => {

    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openDelete, setOpenDelete] = React.useState(false);
    const handleClickOpenDelete = () => {setOpenDelete(true);};
    const handleCloseDelete = () => {setOpenDelete(false);};

    const [rowData] = useState([
        { Operation: 'Assembly'},
        { Operation: 'Glueing'},
        { Operation: 'Cutting'},
        { Operation: 'Packing'},
    ]);

    const [columnDefs] = useState([
        { 
        field: 'Operation' , 
        headerName: "Operations", 
        sortable: true, 
        rowDrag: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'},
        },
        { 
        field: 'action' , 
        headerName: "Action", 
        cellRenderer: function(params) {
            return <div>
                     <IconButton aria-label="edit" color="primary" onClick={handleOpenEdit}><CreateIcon /></IconButton>
                     <IconButton aria-label="delete" color="error" onClick={handleClickOpenDelete}><DeleteIcon /></IconButton>
                    </div> 
        },
        maxWidth: 120,
        },
        
        ]);

        const defaultColDef = useMemo(() => {
            return {
            flex: 1,
            // resizable: true,
            sortable: true,
            wrapText: true,     // <-- HERE
            autoHeight: true, 
            
            };
        }, []);    
    
  return (
        <>
        <Header/>
         <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ maxWidth: 1300 }} className="mt-50" style={{backgroundColor: '#f2f2f2', display: 'flex'}}>
                        <Grid item xs={3}>
                            <div className='setting-nav-menu'>
                                <ul>
                                    <li className='nav-item' >
                                        <NavLink to="/settings/uoms">Units Of Measure <span><ArrowForwardIosIcon/></span></NavLink>
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink to="/settings/taxRates">Tax Rates <span><ArrowForwardIosIcon/></span></NavLink>
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink to="/settings/categories">Categories <span><ArrowForwardIosIcon/></span></NavLink>
                                    </li>
                                    <li className='nav-item active'>
                                        <NavLink to="/settings/operations">Operations <span><ArrowForwardIosIcon/></span></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={9} style={{backgroundColor: '#fff', padding: '10px 40px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}} className='btn-bottom'> 
                                <h3>Operations</h3>
                                <Button size="medium" onClick={handleOpenAdd}><span><AddIcon/></span>Add operation</Button>
                            </div>
                            <div className="ag-theme-alpine" style={{height: 400, width: 300, marginBottom:'30px'}}>
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowDragManaged={true}
                                >
                                </AgGridReact>
                            </div>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
         </Container>
        {/* Add Modal Start*/}
        <BootstrapDialog
            onClose={handleCloseAdd}
            aria-labelledby="customized-dialog-title"
            open={openAdd}
        >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseAdd}>
          Add Operations
        </BootstrapDialogTitle>
       
            <DialogContent dividers style={{width:'500px'}}>
                <Formik
                    initialValues={{
                        Operation: '',
                    }}
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        // alert(JSON.stringify(values, null, 2));
                        console.log(values);
                    }}
                >
                    {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting, }) => (
                        <Form onSubmit={handleSubmit}>
                            <TextField id="Operation" label="Operation" variant="standard" className='w-100'   onChange={handleChange}/>
                            {/* <button type="submit">Submit</button> */}
                            <Button type="submit" variant="contained" color="primary" autoFocus className='btn-modal'>Add Operation</Button>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </BootstrapDialog>
      {/* Add Modal End */}
        {/* Edit Modal Start*/}
        <BootstrapDialog
            onClose={handleCloseEdit}
            aria-labelledby="customized-dialog-title"
            open={openEdit}
        >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseEdit}>
          Edit Operations
        </BootstrapDialogTitle>
       
            <DialogContent dividers style={{width:'500px'}}>
                <Formik
                    initialValues={{
                        Operation: '',
                    }}
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        // alert(JSON.stringify(values, null, 2));
                        console.log(values);
                    }}
                >
                    {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting, }) => (
                        <Form onSubmit={handleSubmit}>
                            <TextField id="Operation" label="Operation" variant="standard" className='w-100'   onChange={handleChange}/>
                            {/* <button type="submit">Submit</button> */}
                            <Button type="submit" variant="contained" color="primary" autoFocus className='btn-modal'>Update</Button>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </BootstrapDialog>
      {/* Edit Modal End */}
         <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Delete the Operation?"}
            </DialogTitle>
            <DialogContent style={{width:'500px'}}>
            <DialogContentText id="alert-dialog-description">
                This action cannot be undone
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDelete}>Cancel</Button>
            <Button onClick={handleCloseDelete} variant="contained" color="error" autoFocus>
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        </>
  )
}

export default Operations;