import React from 'react'
import Header from '../../components/Header/Header'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { NavLink } from 'react-router-dom';
import ManufacturingOrdersOpen from './ManufacturingOrdersOpen';

const Make = () => {

const [value, setValue] = React.useState('1');

const handleChange = (event, newValue) => {
    setValue(newValue);
};

  return (
    <>
         <Header/>
         <Container maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className='title-nav'>
                        <NavLink activeclassname="active" to="/manufacturingorders" className='tab-header'>Schedule</NavLink>
                        <NavLink activeclassname="active" to="/#" className='tab-header'>Tasks</NavLink>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='grid-tabs-container'>
                        <Grid item xs={12}>
                            <TabContext value={value}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Open" value="1"  className='tab-custom'/>
                                    <Tab label="Done" value="2"  className='tab-custom'/>
                                </TabList>
                                <TabPanel value="1" className='tab-spacing'> 
                                  <ManufacturingOrdersOpen/>
                                </TabPanel>
                                <TabPanel value="2" className='tab-spacing'>
                                    {/* <SalesOrderDone/> */}
                                </TabPanel>
                            </TabContext>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
         </Container>
    </>
    
  )
}

export default Make