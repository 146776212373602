import React,{useMemo, useState} from 'react'
import Grid from '@mui/material/Grid';
import { Container, Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Suppliers = () => {

    const [openDelete, setOpenDelete] = React.useState(false);
    const handleClickOpenDelete = () => {setOpenDelete(true);};
    const handleCloseDelete = () => {setOpenDelete(false);};

    const [rowData] = useState([
        { name: 'test', email: 'abc@gmail.com', phone: 'Rob Decor', comment: 'test' },
    
        ]);
    
    const [columnDefs] = useState([
    { 
        field: '' , 
        headerCheckboxSelection: true,
        checkboxSelection: true, 
        filter: false,
        maxWidth: 50,
    },
    { 
       field: 'name' , 
       headerName: "Name", 
       sortable: true, 
    },
    
    { 
        field: 'email', 
        headerName: "E-mail Address", 
        sortable: true, 
    },
    { 
        field: 'comment' , 
        headerName: "Comment", 
        sortable: true, 
    },
    { 
        field: 'action' , 
        headerName: "",
        filter: false,
        cellRenderer: function(params) {
            return <div>
                        <IconButton aria-label="delete" color="light" onClick={handleClickOpenDelete}><DeleteIcon /></IconButton>
                    </div>
        },
        maxWidth: 80,
    },
    
    ]);
    
    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          filter: true,
          floatingFilter: true,
          autoHeight: true, 
        };
      }, []);    

  return (
    <>
        <Container maxWidth={false}>
            <Grid container>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <div className='action-buttons'>
                        <IconButton disabled ><DeleteIcon/></IconButton>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{height: 400}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            rowDragManaged={true}
                            defaultColDef={defaultColDef}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </Container>
        {/* Delete Modal start */}
        <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Delete the supplier ?"}
            </DialogTitle>
            <DialogContent style={{width:'500px'}}>
            <DialogContentText id="alert-dialog-description">
                This action cannot be undone
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDelete}>Cancel</Button>
            <Button onClick={handleCloseDelete} variant="contained" color="error" autoFocus>
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        {/* Delete Modal End */}
    </>
  )
}

export default Suppliers