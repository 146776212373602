import React from 'react'
import { Button, Tab } from '@mui/material';
import { NavLink } from 'react-router-dom';
import StoreIcon from '@mui/icons-material/Store';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';

export default function Header() {
  
  return (
    <>
        <div className='header-wrapper'>
            <Button size="large" className='btn-theme'>Maruti Meditech</Button>
              <NavLink  activeclassname="active" to="/sales" className='menu-item'><Tab icon={<StoreIcon/>} label="SELL" /></NavLink>  
              <NavLink  activeclassname="active" to="/manufacturingorders"  className='menu-item'><Tab icon={<ThumbsUpDownIcon/>} label="MAKE" /></NavLink>  
              <NavLink  activeclassname="active" to="/#" className='menu-item'><Tab icon={<ShoppingBasketIcon />} label="BUY" /></NavLink>  
              {/* <NavLink  activeclassname="active" to="/#" className='menu-item'><Tab icon={<WidgetsIcon/>} label="STOCK" /></NavLink>   */}
              <NavLink  activeclassname="active" to="/Products" className='menu-item'><Tab icon={<LocalActivityIcon />} label="ITEMS" /></NavLink>  
              <NavLink  activeclassname="active" to="/contacts/customers" className='menu-item'><Tab icon={<GroupIcon/>} label="CONTACTS" /></NavLink>  
              <NavLink  activeclassname="active" to="/settings/uoms" className='menu-item'><Tab icon={<SettingsIcon/>} label="SETTINGS" /></NavLink>  
        </div>
    </>
    
  );
}