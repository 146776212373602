import React,{useMemo, useState} from 'react'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const SalesOrderDone = () => {
    const [rowData] = useState([
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
        { order: 'SO-2', customer: 'Rob Decor', totalAmount: '35000.00', tax: '500.00', costOfGoodSold: '2000.00', profit: '500.00', pickedDate:'2023-02-10', delivery:'Delivered' },
    ]);
    
    const [columnDefs] = useState([
    
    { field: '' , checkboxSelection: true, filter: false, sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'},  maxWidth: 50,},
    { field: 'order', headerName: "Order#", sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'}},
    { field: 'customer' , headerName: "Customer", sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'}},
    { field: 'totalAmount' , headerName: "Total Amount", sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'}},
    { field: 'tax' , headerName: "Tax", sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'}},
    { field: 'costOfGoodSold' , headerName: "Cost Of Good Sold", sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'}},
    { field: 'profit' , headerName: "Profit", sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'}},
    { field: 'pickedDate', headerName: "Picked Date", sortable: true, cellStyle: {'borderRightColor': '#e2e2e2'}, filter: 'agDateColumnFilter'},
    { field: 'delivery' , headerName: "Delivery", sortable: true, filter: false, cellStyle: {'borderRightColor': '#e2e2e2'},
        cellStyle: {
            // you can use either came case or dashes, the grid converts to whats needed
            backgroundColor: '#348558', color:'#fff', textAlign:'center' // light green
        },
    },
    ]);
    
    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          minWidth: 150,
          filter: true,
          floatingFilter: true,
          resizable: true,
          sortable: true,
          wrapText: true,     // <-- HERE
          autoHeight: true, 
        };
      }, []);    

  return (
    <>
        <Container maxWidth={false}>
            <Grid container>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <div className='action-buttons'>
                        <IconButton color="primary"><FileDownloadOutlinedIcon/></IconButton>
                        <IconButton disabled ><PrintIcon/></IconButton>
                        <IconButton disabled ><EditIcon/></IconButton>
                        <IconButton disabled ><DeleteIcon/></IconButton>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{height: 600}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            // rowSelection={'single'}
                            rowDragManaged={true}
                            defaultColDef={defaultColDef}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default SalesOrderDone