import React,{useMemo, useState} from 'react'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import DeliveryStatus from './DeliveryStatus';
// import 'ag-grid-enterprise';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const ManufacturingOrdersOpen = () => {
    const [rowData] = useState([
        { rank: '1', order: 'SO-2', customer: 'Rob Decor', totalAmount: 35000.00, deliveryDeadline:'2023-02-10', salesItems:'Expected' , salesDate:'2023-02-14', ingredients:'In Stock', production:'Work in progress', delivery:'Not Shipped' },
       
    
        ]);
    
    const [columnDefs] = useState([
    { 
       field: 'rank' , 
       headerName: "Rank", 
       sortable: true, 
       rowDrag: true, 
       filter: false, 
       cellStyle: {'borderRightColor': '#e2e2e2', fontWeight: 600}, maxWidth: 80,
    },
    { 
        field: '' , 
        checkboxSelection: true, 
        filter: false,
        cellStyle: {'borderRightColor': '#e2e2e2'},  maxWidth: 50,

    },
    { 
        field: 'order', 
        headerName: "Order#", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'},
        cellRenderer: function(params) {
            return <a href="#" target="_blank" rel="noopener"> {params.value} </a>
        }
    },
    { 
        field: 'customer' , 
        headerName: "Customer",
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'}
    },
    { 
        field: 'product' , 
        headerName: "Product", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'},
    },
    { 
        field: 'category' , 
        headerName: "Category", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'},
    },
    { 
        field: 'completed' , 
        headerName: "Completed", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'},
    },
    { 
        field: 'qty' , 
        headerName: "Quantity", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'},
    },
    { 
        field: 'plannedTime', 
        headerName: "Planned Time", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2', color: '#e42c00'},
        filter: 'agDateColumnFilter', 
    },
    { 
        field: 'productDeadline', 
        headerName: "Prod. Deadline", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2', color: '#e42c00'},
        filter: 'agDateColumnFilter', 
    },
    { 
        field: 'deliveryDeadline', 
        headerName: "del. Deadline", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2', color: '#e42c00'},
        filter: 'agDateColumnFilter', 
    },
    { 
        field: 'ingredients' , 
        headerName: "Ingredients", 
        sortable: true, 
        // cellStyle: {'borderRightColor': '#e2e2e2'},
        cellStyle: params => {
        if (params.value === 'In Stock') {
            //mark police cells as red
            return {color: '#fff', backgroundColor: '#348558'};
        }
        else if (params.value === 'Expected') {
            //mark police cells as red
            return {color: '#000', backgroundColor: '#ffdf6c'};
        }
        else if (params.value === 'Not Availabel') {
            //mark police cells as red
            return {color: '#fff', backgroundColor: '#e42c00'};
        }
        return null;
        }
    },
    { 
        field: 'production' , 
        headerName: "Production", 
        sortable: true, 
        cellStyle: {'borderRightColor': '#e2e2e2'},
        // cellStyle: params => {
        //     if (params.value === 'Done') {
        //         return {color: '#fff', backgroundColor: '#348558'};
        //     }
        //     else if (params.value === 'Work in progress') {
        //         return {color: '#000', backgroundColor: '#ffdf6c'};
        //     }
        //     else if (params.value === 'Blocked') {
        //         return {color: '#fff', backgroundColor: '#e42c00'};
        //     }
        //     return null;
        // },
        cellRenderer: DeliveryStatus, minWidth: 200
    }
    ]);
    
    // const CustomStatusBar = props => {
    //     return (
    //       <div className="ag-status-bar-center">
    //         <div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
    //           <span className="label">Total Rows</span>:&nbsp;
    //           <span className="ag-status-name-value-value">{props.total}</span>
    //         </div>
    //       </div>
    //     );
    //   };

    const statusBar = useMemo(() => {
        return {
          statusPanels: [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
            // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' },
          ],
        };
      }, []);

    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          filter: true,
          floatingFilter: true,
          resizable: true,
          sortable: true,
          wrapText: true,     // <-- HERE
          autoHeight: true, 
        
        };
      }, []);    

  return (
    <>
        <Container maxWidth={false}>
            <Grid container>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <div className='action-buttons'>
                        <IconButton color="primary"><FileDownloadOutlinedIcon/></IconButton>
                        <IconButton disabled ><PrintIcon/></IconButton>
                        <IconButton disabled ><EditIcon/></IconButton>
                        <IconButton disabled ><DeleteIcon/></IconButton>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{height: 400}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            // rowSelection={'single'}
                            rowDragManaged={true}
                            defaultColDef={defaultColDef}
                            // pagination={true}
                            statusBar={statusBar}
                            // frameworkComponents={{
                            //     customStatusBar: CustomStatusBar
                            //   }}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </Container>
    </>
  )
}

export default ManufacturingOrdersOpen