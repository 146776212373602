import React from 'react'
import Header from '../../components/Header/Header'
import Grid from '@mui/material/Grid';
import { Container, Card, TextField, Button } from '@mui/material';
import { Formik, Form } from 'formik';
import PhoneIcon from '@mui/icons-material/Phone';
import InputAdornment from '@mui/material/InputAdornment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      width: '800px',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const currencies = [
  {
    value: 'ABC',
    label: 'ABC',
  },
  {
    value: 'QWERTY',
    label: 'QWERTY',
  },
  {
    value: 'XYZ',
    label: 'XYZ',
  },
];


const AddCustomer = () => {
    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const [openAdd2, setOpenAdd2] = React.useState(false);
    const handleOpenAdd2 = () => setOpenAdd2(true);
    const handleCloseAdd2 = () => setOpenAdd2(false);
    
  return (
    <>
         <Header/>
         <Container maxWidth={false}>
            <Grid container className='pl-50 pr-50'>
                <Grid item xs={12}>
                    <Card className="mt-50" style={{backgroundColor: '#fff'}}>
                        <Grid item xs={12}>
                            <div className='card-header'>
                                <p>Customer</p>
                                <h4>Customer Name</h4>
                            </div>
                        </Grid>
                        <Formik
                            initialValues={{
                                uom: '',
                            }}
                            onSubmit={async (values) => {
                                await new Promise((r) => setTimeout(r, 500));
                                // alert(JSON.stringify(values, null, 2));
                                console.log(values);
                            }}
                        >
                        {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting, }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container className='card-body'>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={6} className='form-custom'>
                                                <TextField 
                                                    id="firstname" 
                                                    label="First Name" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className='form-custom'>
                                                <TextField 
                                                    id="lastname" 
                                                    label="Last Name" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="companyname" 
                                                    label="Company Name" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="displayName"
                                                    select
                                                    label="Display Name"
                                                    defaultValue="ABC"
                                                    variant="standard"
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                    >
                                                    {currencies.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="Email"
                                                    InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                        <MailOutlineIcon/>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    type="email"
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="Phone"
                                                    InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                        <PhoneIcon/>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    type="number"
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="comment" 
                                                    label="Comment" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid Container>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="billingAddress"
                                                    label="Billing Address"
                                                    InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                        <LocationOnRoundedIcon/>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    placeholder="Enter Address"
                                                    type="text"
                                                    variant="standard"
                                                    className='w-100'  
                                                    // onChange={handleChange}
                                                    onClick={handleOpenAdd}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="shippingAddress"
                                                    label="Default Shipping Address"
                                                    InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                        <LocationOnRoundedIcon/>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    placeholder="Same as billing address"
                                                    type="text"
                                                    variant="standard"
                                                    className='w-100'  
                                                    // onChange={handleChange}
                                                    onClick={handleOpenAdd2}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{textAlign: 'right', marginBottom: '20px'}}>
                                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                        </Formik>
                    </Card>
                </Grid>
            </Grid>
         </Container>
         {/* Billing Modal Start*/}
         <BootstrapDialog
                onClose={handleCloseAdd}
                aria-labelledby="customized-dialog-title"
                open={openAdd}
                maxWidth={"lg"}
            >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseAdd}>
            Billing Address
            </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Formik
                        initialValues={{
                            uom: '',
                        }}
                        onSubmit={async (values) => {
                            await new Promise((r) => setTimeout(r, 500));
                            // alert(JSON.stringify(values, null, 2));
                            console.log(values);
                        }}
                    >
                        {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting, }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="firstname" 
                                                    label="First Name" 
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} 
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="lastname" 
                                                    label="Last Name" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="companyname" 
                                                    label="Company Name" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="Phone"
                                                    InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                        <PhoneIcon/>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    type="number"
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Address line 1"
                                                    type="text"
                                                    placeholder='Enter Street Name'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Address line 2"
                                                    type="text"
                                                    placeholder='Address line 2 (e.g., Apartment, Unit, Suite or Building)'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="City/Town"
                                                    type="text"
                                                    placeholder='City, District, Town or Village'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="State/Region"
                                                    type="text"
                                                    placeholder='State, Province, Region'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Zip/Postal Code"
                                                    type="text" 
                                                    placeholder='Zip or Postal Code'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Country"
                                                    type="text"
                                                    placeholder='Country'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogActions>
                                        <Button onClick={handleCloseAdd}>cancel</Button>
                                        <Button type="submit" variant="contained" color="primary">OK</Button>
                                    </DialogActions>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </BootstrapDialog>
        {/* Billing Modal End */}
        {/* Shipping Modal Start*/}
         <BootstrapDialog
                onClose={handleCloseAdd2}
                aria-labelledby="customized-dialog-title"
                open={openAdd2}
                maxWidth={"lg"}
            >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseAdd2}>
            Shipping Address
            </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Formik
                        initialValues={{
                            uom: '',
                        }}
                        onSubmit={async (values) => {
                            await new Promise((r) => setTimeout(r, 500));
                            // alert(JSON.stringify(values, null, 2));
                            console.log(values);
                        }}
                    >
                        {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting, }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="firstname" 
                                                    label="First Name" 
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} 
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="lastname" 
                                                    label="Last Name" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField 
                                                    id="companyname" 
                                                    label="Company Name" 
                                                    variant="standard" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='w-100'   
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="Phone"
                                                    InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                        <PhoneIcon/>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    type="number"
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Address line 1"
                                                    type="text"
                                                    placeholder='Enter Street Name'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Address line 2"
                                                    type="text"
                                                    placeholder='Address line 2 (e.g., Apartment, Unit, Suite or Building)'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="City/Town"
                                                    type="text"
                                                    placeholder='City, District, Town or Village'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="State/Region"
                                                    type="text"
                                                    placeholder='State, Province, Region'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Zip/Postal Code"
                                                    type="text" 
                                                    placeholder='Zip or Postal Code'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='form-custom'>
                                                <TextField
                                                    id="filled-number"
                                                    label="Country"
                                                    type="text"
                                                    placeholder='Country'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    className='w-100'  
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogActions>
                                        <Button onClick={handleCloseAdd2}>cancel</Button>
                                        <Button type="submit" variant="contained" color="primary">OK</Button>
                                    </DialogActions>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </BootstrapDialog>
        {/* Shipping Modal End */}
    </>
  )
}

export default AddCustomer